import React from 'react'
import { Fragment } from 'react'

import FeatureImage from '@/images/feature.jpg'

const META = {
  TITLE: 'Gold Mining Metaverse',
  DESCRIPTION:
    "World's first gold mining in the virtual world, and can be exchanged for the actual gold.",
}

const Seo = () => {
  return (
    <Fragment>
      <title>{META.TITLE}</title>
      <meta name="description" content={META.DESCRIPTION} />
      <meta property="og:title" content={META.TITLE} />
      <meta property="og:description" content={META.DESCRIPTION} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={FeatureImage} />
      <meta name="twitter:creator" content="WellExp" />
      <meta name="twitter:title" content={META.TITLE} />
      <meta name="twitter:description" content={META.DESCRIPTION} />
      <meta name="twitter:card" content="summary_large_image" />
    </Fragment>
  )
}

export default Seo
