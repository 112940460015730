import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Input,
  Link,
  Text,
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

import useSubscribe from '@/hooks/useSubscribe'

const CommunitySection = () => {
  const [validateEmail, setValidateEmail] = useState(false)
  const [email, setEmail] = useState<string>('')

  const {
    subscribeMessage,
    setSubscribeMessage,
    handleSubscribeEmail,
    loading: isSubscribeLoading,
  } = useSubscribe()

  const emailisValid = (email: string) => {
    const filter =
      // eslint-disable-next-line no-useless-escape
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/
    return String(email).search(filter) != -1
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const input = e.target.value
    setEmail(input)
    const validatedEmail = emailisValid(input)
    if (validatedEmail) {
      setValidateEmail(true)
      return
    }

    setValidateEmail(false)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (subscribeMessage.text) {
        setSubscribeMessage({
          status: '',
          text: '',
        })
      }
    }, 4000)

    return () => clearTimeout(timeoutId)
  }, [setSubscribeMessage, subscribeMessage.text])

  return (
    <Box
      id={'community-section'}
      as={'section'}
      // overflow={'visible'}
      mt={'-10.2em'}
      pb={'2.917em'}
    >
      {/* Dust Top */}
      <Box
        position={'absolute'}
        zIndex={-1}
        width={'full'}
        height={'10.72em'}
        bg="#060606"
        filter={'blur(3.24em)'}
        top={'0'}
      />

      {/* Subscribe */}
      <Box
        w={'79.167em'}
        bgColor={'rgba(255,255,255,0.1)'}
        px={'8.333em'}
        py={'3.125em'}
        mx={'auto'}
        borderRadius={'0.521em'}
        sx={{
          '@media (max-width: 768px)': {
            w: '45.167em',
            px: '3.333em',
            py: '3.125em',
          },
        }}
      >
        <Grid
          templateColumns={'28.8em 1fr'}
          columnGap={'7.135em'}
          alignItems={'center'}
          sx={{
            '@media (max-width: 768px)': {
              gridTemplateColumns: '1fr',
              columnGap: '0',
              rowGap: '2.4em',
            },
          }}
        >
          <GridItem>
            <Text fontSize={'2xl'} fontWeight={'bold'} letterSpacing={'0.1em'}>
              Join Community
            </Text>
            <Text
              mt={'0.417em'}
              fontSize={'md'}
              color={'light'}
              letterSpacing={'0.05em'}
            >
              Subscribe for the latest our news and activities at{' '}
              <Link
                href="https://bit.ly/3MAjE1m"
                target="_blank"
                rel="noreferrer"
                textDecoration={'underline'}
                fontWeight={'bold'}
              >
                LINE Openchat
              </Link>
            </Text>
          </GridItem>
          <GridItem>
            <Box
              bgColor={'rgba(255,255,255,0.1)'}
              p={'0.417em'}
              borderRadius={'0.208em'}
              position={'relative'}
            >
              <FormControl display={'flex'}>
                <Input
                  h={'auto'}
                  w={'15.417em'}
                  size={'md'}
                  border={'none'}
                  focusBorderColor="none"
                  placeholder="Enter your email"
                  _placeholder={{ color: 'light' }}
                  fontSize={'md'}
                  onChange={handleEmailChange}
                  autoComplete={'off'}
                  sx={{
                    '@media (max-width: 768px)': {
                      w: 'full',
                    },
                  }}
                />
                <Box
                  display={'flex'}
                  ml={'auto'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  w={'12.500em'}
                  h={'2.917em'}
                  as={'button'}
                  bgColor={'rgba(255,255,255,0.5)'}
                  borderRadius={'0.208em'}
                  py={'0.990em'}
                  px={'2.604em'}
                  disabled={!validateEmail}
                  opacity={validateEmail ? '1' : '0.4'}
                  onClick={() => handleSubscribeEmail(email)}
                  sx={{
                    '@media (max-width: 768px)': {
                      w: '18.5em',
                    },
                  }}
                >
                  {isSubscribeLoading ? (
                    <Button isLoading variant="ghost" />
                  ) : (
                    <Text color={'#333333'} fontSize={'md'}>
                      Subcribe Now
                    </Text>
                  )}
                </Box>
              </FormControl>
              {subscribeMessage.text && (
                <Text
                  color={
                    subscribeMessage.status === 'success' ? 'green' : 'red'
                  }
                  position={'absolute'}
                  top={'4.167em'}
                >
                  {subscribeMessage.text}
                </Text>
              )}
            </Box>
          </GridItem>
        </Grid>
      </Box>

      <Flex
        gap={'1.25em'}
        mx={'auto'}
        justifyContent={'center'}
        mt={'1.875em'}
        sx={{
          '@media (max-width: 768px)': {
            gap: '3em',
            mt: '4.375em',
          },
        }}
      >
        <Link
          href="https://www.facebook.com/XWELBYWELLEXP"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            w={'1.4em'}
            h={'1.4em'}
            sx={{
              '@media (max-width: 768px)': {
                w: '2.083em',
                h: '2.083em',
              },
            }}
          >
            <StaticImage
              src="../../images/pages/social-facebook.png"
              alt="Facebook"
              layout={'fullWidth'}
              quality={100}
            />
          </Box>
        </Link>
        <Link href="http://t.me/WELLEXP" target="_blank" rel="noreferrer">
          <Box
            w={'1.4em'}
            h={'1.4em'}
            sx={{
              '@media (max-width: 768px)': {
                w: '2.083em',
                h: '2.083em',
              },
            }}
          >
            <StaticImage
              src="../../images/pages/social-telegram.png"
              alt="Telegram"
              layout={'fullWidth'}
              quality={100}
            />
          </Box>
        </Link>
        <Link
          href="https://twitter.com/XWELTOKEN"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            w={'1.4em'}
            h={'1.4em'}
            sx={{
              '@media (max-width: 768px)': {
                w: '2.083em',
                h: '2.083em',
              },
            }}
          >
            <StaticImage
              src="../../images/pages/social-twitter.png"
              alt="Twitter"
              layout={'fullWidth'}
              quality={100}
            />
          </Box>
        </Link>
        <Link href="https://bit.ly/3MAjE1m" target="_blank" rel="noreferrer">
          <Box
            w={'1.4em'}
            h={'1.4em'}
            sx={{
              '@media (max-width: 768px)': {
                w: '2.083em',
                h: '2.083em',
              },
            }}
          >
            <StaticImage
              src="../../images/pages/social-line.png"
              alt="Line"
              layout={'fullWidth'}
              quality={100}
            />
          </Box>
        </Link>
        <Link
          href="https://youtube.com/channel/UCnbla4kGPUyKWvVBvYE_hUQ"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            w={'1.4em'}
            h={'1.4em'}
            sx={{
              '@media (max-width: 768px)': {
                w: '2.083em',
                h: '2.083em',
              },
            }}
          >
            <StaticImage
              src="../../images/pages/social-youtube.png"
              alt="Youtube"
              layout={'fullWidth'}
              quality={100}
            />
          </Box>
        </Link>
      </Flex>

      <Flex
        gap={'1.25em'}
        mx={'auto'}
        justifyContent={'center'}
        mt={'1.875em'}
        alignItems={'center'}
        fontSize={'sm'}
        sx={{
          '@media (max-width: 768px)': {
            fontSize: 'xl',
          },
        }}
      >
        <Link href="https://docs.xwel.io/" target="_blank" rel="noreferrer">
          FAQ
        </Link>
        <Divider h={'0.469em'} orientation="vertical" />
        <Link href="https://docs.xwel.io/" target="_blank" rel="noreferrer">
          Help
        </Link>
      </Flex>
    </Box>
  )
}

export default CommunitySection
