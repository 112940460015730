import React from 'react'

import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

const EcosystemSection = () => {
  return (
    <Box
      id={'ecosystem-section'}
      as={'section'}
      mt={'8.875em'}
      sx={{
        '@media (max-width: 768px)': {
          mt: '12.42  em',
        },
      }}
    >
      <Flex flexDir={'column'} justifyContent={'center'}>
        <Text color={'primary'} textStyle={'header-title'} textAlign={'center'}>
          Ecosystem
        </Text>
        <Divider w={'1.67em'} mx={'auto'} pt={'2.34em'} />
        <Box w={{ base: '40em', lg: '90em' }} mx={'auto'}>
          <StaticImage
            src="../../images/pages/ecosystem-overview.png"
            alt="Ecosystem Overview Image"
            quality={100}
            layout="fullWidth"
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default EcosystemSection
