import { useState } from 'react'

import { HUBSPOT_FORM_GUID, HUBSPOT_PORTAL_ID } from '@/constants/hubspot'
import {
  ISubscribeResponse,
  ISubscribeState,
  ISubscribeSuccess,
} from '@/types/subscribe'

const useSubscribe = () => {
  const [subscribeMessage, setSubscribeMessage] = useState<ISubscribeState>({
    status: '',
    text: '',
  })

  const [loading, setLoading] = useState(false)

  const handleSubscribeEmail = async (email: string) => {
    try {
      // STEP 1 Validate email on hubspot
      setLoading(true)
      const validateResponse = await validateEmailOnHubspot(email)
      const data: ISubscribeResponse = await validateResponse.json()

      if (!data.success) {
        setSubscribeMessage({
          status: 'fail',
          text: 'subscribe failed please check your email',
        })
        setLoading(false)
        return
      }

      // STEP 2 Sent email on hubspot
      const subscribeResponse = await sendEmailToHubspot(email)
      const subscribeData: ISubscribeSuccess = await subscribeResponse.json()

      if (subscribeData.inlineMessage) {
        setSubscribeMessage({
          status: 'success',
          text: subscribeData.inlineMessage,
        })
        setLoading(false)
      }
    } catch (e: any) {
      console.error(e)
      setLoading(false)
    }
  }

  const validateEmailOnHubspot = async (email: string) => {
    return await fetch(
      `https://forms.hsforms.com/emailcheck/v1/json-ext?portalId=${HUBSPOT_PORTAL_ID}&includeFreemailSuggestions=false`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: email,
      }
    )
  }

  const sendEmailToHubspot = async (email: string) => {
    const subscribePayload = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ],
    }

    return await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_GUID}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscribePayload),
      }
    )
  }

  return {
    handleSubscribeEmail,
    subscribeMessage,
    setSubscribeMessage,
    loading,
  }
}

export default useSubscribe
