import React from 'react'

import { Box, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import Button from '../Base/Button'

const MarketplaceSection = () => {
  const { t } = useTranslation(['marketplace'])

  return (
    <Box
      id={'marketplace-section'}
      as={'section'}
      pb={'4.72em'}
      pt={'8.72em'}
      zIndex={'1'}
    >
      <Flex flexDir={'column'} justifyContent={'center'}>
        <Box w={'90%'} mx={'auto'}>
          <Text
            color={'primary'}
            textStyle={'header-title'}
            textAlign={'center'}
          >
            Gold NFT
            <Text
              as={'span'}
              color={'white'}
              display="inline-block"
              ml={'0.417em'}
            >
              Marketplace
            </Text>
          </Text>
        </Box>

        <Grid
          templateColumns={'22.354em 45.885em'}
          columnGap={'5.708em'}
          mt={'7.52em'}
          mx={'auto'}
          sx={{
            '@media (max-width: 768px)': {
              gridTemplateColumns: '1fr',
              columnGap: '0',
              mx: 'auto',
              mt: '0',
            },
          }}
        >
          <GridItem
            mt={'4.125em'}
            sx={{
              '@media (max-width: 768px)': {
                w: '32em',
                mx: 'auto',
                mt: '2.125em',
              },
            }}
          >
            <Divider
              w={'1.042em'}
              sx={{
                '@media (max-width: 768px)': {
                  mx: 'auto',
                },
              }}
            />
            <Text
              mt={'1.875em'}
              textStyle={'description-text'}
              sx={{
                '@media (max-width: 768px)': {
                  textAlign: 'center',
                  fontSize: '2xl',
                },
              }}
            >
              <Trans t={t} i18nKey={'description'} />
            </Text>
            <Button.LinkButton
              href="https://www.binance.com/en/nft/collection/gold-mining-equipment-603113912912527360"
              target="_blank"
              rel="noreferrer"
              variant={'primary'}
              bgColor={'#FCE4A6'}
              w={'full'}
              h={'2.65625em'}
              py={'0.83em'}
              mt={'3.2em'}
              sx={{
                '@media (max-width: 768px)': {
                  mx: 'auto',
                  w: '14.25em',
                  h: '4.65625em',
                },
              }}
            >
              <Text
                as="span"
                fontSize={'md'}
                fontWeight={'bold'}
                color={'black'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: '2xl',
                  },
                }}
              >
                Binance Nft
              </Text>
            </Button.LinkButton>
            <Button.LinkButton
              href="https://tofunft.com/collection/gold-mining-equipment/items"
              target="_blank"
              rel="noreferrer"
              variant={'primary'}
              w={'full'}
              h={'2.65625em'}
              py={'0.83em'}
              mt={'1.2em'}
              sx={{
                '@media (max-width: 768px)': {
                  mx: 'auto',
                  w: '14.25em',
                  h: '4.65625em',
                },
              }}
            >
              <Text
                as="span"
                fontSize={'md'}
                fontWeight={'bold'}
                color={'light'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: '2xl',
                  },
                }}
              >
                Tofu Nft
              </Text>
            </Button.LinkButton>
          </GridItem>
          <GridItem position={'relative'}>
            <Box
              w={'45.885em'}
              sx={{
                '@media (max-width: 768px)': {
                  w: '40.885em',
                  mt: '5.6em',
                },
              }}
            >
              <StaticImage
                src="../../images/pages/marketplace-site.png"
                alt="Marketplace Web Application"
                layout={'fullWidth'}
                quality={80}
                objectFit={'contain'}
              />
            </Box>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  )
}

export default MarketplaceSection
