import React from 'react'

import { Box, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { useTranslation } from 'react-i18next'

import { localeConfigs } from '@/constants/locales'

const ServiceSection = () => {
  const { t } = useTranslation('service')
  const { language } = useI18next()

  return (
    <Box
      id={'service-section'}
      as={'section'}
      pt={'6.30em'}
      sx={{
        '@media (max-width: 768px)': {
          pt: '0',
        },
      }}
    >
      <Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
        <Box w={'90%'} mx={'auto'}>
          <Text
            color={language === localeConfigs['th'].code ? 'white' : 'primary'}
            textStyle={'header-title'}
            textAlign={'center'}
          >
            {t('service.headerText')}{' '}
            <Text as={'span'} color={'primary'}>
              Gold Mining Metaverse
            </Text>
          </Text>
        </Box>
        <Grid
          templateColumns="repeat(2, 1fr)"
          rowGap={'4em'}
          columnGap={'4em'}
          mt={'7.8125em'}
          color={'light'}
          letterSpacing={'0.05em'}
          sx={{
            '@media (max-width: 768px)': {
              gridTemplateColumns: '1fr',
              mt: '4.6em',
            },
          }}
        >
          <GridItem w="100%">
            <Grid templateColumns="1.04em 26em" columnGap={'3.125em'}>
              <GridItem>
                <Divider mt="0.85em" />
              </GridItem>
              <GridItem>
                <Text lineHeight={'120%'} fontWeight={'bold'} fontSize={'2xl2'}>
                  {t('service.label.1')}
                </Text>
                <Text
                  lineHeight={'120%'}
                  fontSize={'lg2'}
                  marginTop={'0.6em'}
                  sx={{
                    '@media (max-width: 768px)': {
                      fontSize: '2xl',
                    },
                  }}
                >
                  {t('service.desc.1')}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem w="100%">
            <Grid templateColumns="1.04em 26em" columnGap={'3.125em'}>
              <GridItem>
                <Divider mt="0.85em" />
              </GridItem>
              <GridItem>
                <Text lineHeight={'120%'} fontWeight={'bold'} fontSize={'2xl2'}>
                  {t('service.label.2')}
                </Text>
                <Text
                  lineHeight={'120%'}
                  fontSize={'lg2'}
                  marginTop={'0.6em'}
                  sx={{
                    fontSize: '2xl',
                  }}
                >
                  {t('service.desc.2')}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem w="100%">
            <Grid templateColumns="1.04em 26em" columnGap={'3.125em'}>
              <GridItem>
                <Divider mt="0.85em" />
              </GridItem>
              <GridItem>
                <Text lineHeight={'120%'} fontWeight={'bold'} fontSize={'2xl2'}>
                  {t('service.label.3')}
                </Text>
                <Text
                  lineHeight={'120%'}
                  fontSize={'lg2'}
                  marginTop={'0.6em'}
                  sx={{
                    fontSize: '2xl',
                  }}
                >
                  {t('service.desc.3')}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem w="100%">
            <Grid templateColumns="1.04em 26em" columnGap={'3.125em'}>
              <GridItem>
                <Divider mt="0.85em" />
              </GridItem>
              <GridItem>
                <Text lineHeight={'120%'} fontWeight={'bold'} fontSize={'2xl2'}>
                  {t('service.label.4')}
                </Text>
                <Text
                  lineHeight={'120%'}
                  fontSize={'lg2'}
                  marginTop={'0.6em'}
                  sx={{
                    fontSize: '2xl',
                  }}
                >
                  {t('service.desc.4')}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem w="100%">
            <Grid templateColumns="1.04em 26em" columnGap={'3.125em'}>
              <GridItem>
                <Divider mt="0.85em" />
              </GridItem>
              <GridItem>
                <Text lineHeight={'120%'} fontWeight={'bold'} fontSize={'2xl2'}>
                  {t('service.label.5')}
                </Text>
                <Text
                  lineHeight={'120%'}
                  fontSize={'lg2'}
                  marginTop={'0.6em'}
                  sx={{
                    fontSize: '2xl',
                  }}
                >
                  {t('service.desc.5')}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem w="100%">
            <Grid templateColumns="1.04em 26em" columnGap={'3.125em'}>
              <GridItem>
                <Divider mt="0.85em" />
              </GridItem>
              <GridItem>
                <Text lineHeight={'120%'} fontWeight={'bold'} fontSize={'2xl2'}>
                  {t('service.label.6')}
                </Text>
                <Text
                  lineHeight={'120%'}
                  fontSize={'lg2'}
                  marginTop={'0.6em'}
                  sx={{
                    fontSize: '2xl',
                  }}
                >
                  {t('service.desc.6')}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem w="100%">
            <Grid templateColumns="1.04em 26em" columnGap={'3.125em'}>
              <GridItem>
                <Divider mt="0.85em" />
              </GridItem>
              <GridItem>
                <Text lineHeight={'120%'} fontWeight={'bold'} fontSize={'2xl2'}>
                  {t('service.label.7')}
                </Text>
                <Text
                  lineHeight={'120%'}
                  fontSize={'lg2'}
                  marginTop={'0.6em'}
                  sx={{
                    fontSize: '2xl',
                  }}
                >
                  {t('service.desc.7')}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  )
}

export default ServiceSection
