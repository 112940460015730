import React from 'react'

import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

const FeatureSection = () => {
  const { t } = useTranslation('equipment')

  return (
    <Box
      id={'feature-section'}
      as={'section'}
      pt={'4.375em'}
      pb={'12.5em'}
      sx={{
        '@media (max-width: 768px)': {
          pt: '8.42em',
        },
      }}
    >
      {/* Dust Top */}
      <Box
        position={'absolute'}
        zIndex={-1}
        width={'full'}
        height={'38.333em'}
        bg="#060606"
        filter={'blur(4.010em)'}
        bottom={'16.432em'}
        sx={{
          '@media (max-width: 768px)': {
            bottom: '0',
          },
        }}
      />

      {/* Left // Gold Bar */}
      <Box
        w={'21.510em'}
        bottom={'2.4em'}
        left={'0'}
        zIndex={-1}
        position={'absolute'}
        sx={{
          '@media (max-width: 768px)': {
            w: '13.510em',
          },
        }}
      >
        <StaticImage
          src="../../images/pages/feature-goldleft.png"
          alt="Gold bar"
          layout={'fullWidth'}
          quality={75}
        />
      </Box>

      {/* Right // Gold Bar */}
      <Box
        w={'21.406em'}
        bottom={'2em'}
        right={'0'}
        zIndex={-1}
        position={'absolute'}
        sx={{
          '@media (max-width: 768px)': {
            w: '13.510em',
          },
        }}
      >
        <StaticImage
          src="../../images/pages/feature-goldright.png"
          alt="Gold bar"
          layout={'fullWidth'}
          quality={75}
        />
      </Box>

      {/* Background */}
      <Box
        position={'absolute'}
        bottom={'0'}
        zIndex={-2}
        w={'full'}
        h={'32.865em'}
        sx={{
          '@media (max-width: 768px)': {
            h: 'auto',
          },
        }}
      >
        <StaticImage
          src="../../images/pages/background-goldscape.png"
          alt="Gold landscape"
          layout={'fullWidth'}
          quality={100}
          objectFit={'cover'}
        />
      </Box>

      <Flex flexDir={'column'} justifyContent={'center'}>
        <Box w={'90%'} mx={'auto'}>
          <Text color={'white'} textStyle={'header-title'} textAlign={'center'}>
            {t('feature.headerText.line1')}
          </Text>
          <Text
            color={'primary'}
            textStyle={'header-title'}
            textAlign={'center'}
          >
            {t('feature.headerText.line2')}
          </Text>
        </Box>

        <Grid
          templateColumns={'repeat(2,30em)'}
          gap={'2.5em'}
          mx={'auto'}
          mt={'6.333em'}
          sx={{
            '@media (max-width: 768px)': {
              gridTemplateColumns: '1fr',
              mt: '4.6em',
            },
          }}
        >
          <Grid
            templateColumns={'4.167em 20.417em'}
            alignItems={'center'}
            columnGap={'2.083em'}
            letterSpacing={'0.05em'}
            p={'1.979em 1.25em 1.927em 2.083em'}
            bgColor={'rgba(255,255,255,0.1)'}
            borderRadius={'0.407em'}
          >
            <Box position={'relative'}>
              <StaticImage
                src="../../images/pages/feature-highreturns.png"
                alt="Reward Giving"
                quality={100}
                layout="fullWidth"
              />
            </Box>
            <Flex direction={'column'} color={'light'}>
              <Text
                fontSize={'lg2'}
                fontWeight={'bold'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: '2xl',
                  },
                }}
              >
                {t('feature.service.1.label')}
              </Text>
              <Text
                fontSize={'md'}
                mt={'0.625em'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: 'lg',
                  },
                }}
              >
                {t('feature.service.1.desc')}
              </Text>
            </Flex>
          </Grid>

          <Grid
            templateColumns={'4.167em 20.417em'}
            alignItems={'center'}
            columnGap={'2.083em'}
            letterSpacing={'0.05em'}
            p={'1.979em 1.25em 1.927em 2.083em'}
            bgColor={'rgba(255,255,255,0.1)'}
            borderRadius={'0.407em'}
          >
            <Box position={'relative'}>
              <StaticImage
                src="../../images/pages/feature-holder.png"
                alt="Holder crown"
                quality={100}
                layout="fullWidth"
              />
            </Box>
            <Flex direction={'column'} color={'light'}>
              <Text
                fontSize={'lg2'}
                fontWeight={'bold'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: '2xl',
                  },
                }}
              >
                {t('feature.service.2.label')}
              </Text>
              <Text
                fontSize={'md'}
                mt={'0.625em'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: 'lg',
                  },
                }}
              >
                {t('feature.service.2.desc')}
              </Text>
            </Flex>
          </Grid>

          <Grid
            templateColumns={'4.167em 20.417em'}
            alignItems={'center'}
            columnGap={'2.083em'}
            letterSpacing={'0.05em'}
            p={'1.979em 1.25em 1.927em 2.083em'}
            bgColor={'rgba(255,255,255,0.1)'}
            borderRadius={'0.407em'}
          >
            <Box position={'relative'}>
              <StaticImage
                src="../../images/pages/feature-jackpot.png"
                alt="Gift"
                quality={100}
                layout="fullWidth"
              />
            </Box>
            <Flex direction={'column'} color={'light'}>
              <Text
                fontSize={'lg2'}
                fontWeight={'bold'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: '2xl',
                  },
                }}
              >
                {t('feature.service.3.label')}
              </Text>
              <Text
                fontSize={'md'}
                mt={'0.625em'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: 'lg',
                  },
                }}
              >
                {t('feature.service.3.desc')}
              </Text>
            </Flex>
          </Grid>

          <Grid
            templateColumns={'4.167em 20.417em'}
            alignItems={'center'}
            columnGap={'2.083em'}
            letterSpacing={'0.05em'}
            p={'1.979em 1.25em 1.927em 2.083em'}
            bgColor={'rgba(255,255,255,0.1)'}
            borderRadius={'0.407em'}
          >
            <Box position={'relative'}>
              <StaticImage
                src="../../images/pages/feature-liquidity.png"
                alt="Ranking Image"
                quality={100}
                layout="fullWidth"
              />
            </Box>
            <Flex direction={'column'} color={'light'}>
              <Text
                fontSize={'lg2'}
                fontWeight={'bold'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: '2xl',
                  },
                }}
              >
                {t('feature.service.4.label')}
              </Text>
              <Text
                fontSize={'md'}
                mt={'0.625em'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: 'lg',
                  },
                }}
              >
                {t('feature.service.4.desc')}
              </Text>
            </Flex>
          </Grid>

          <Grid
            templateColumns={'4.167em 20.417em'}
            alignItems={'center'}
            columnGap={'2.083em'}
            letterSpacing={'0.05em'}
            p={'1.979em 1.25em 1.927em 2.083em'}
            bgColor={'rgba(255,255,255,0.1)'}
            borderRadius={'0.407em'}
          >
            <Box position={'relative'}>
              <StaticImage
                src="../../images/pages/feature-staking.png"
                alt="Piggy Bank"
                quality={100}
                layout="fullWidth"
              />
            </Box>
            <Flex direction={'column'} color={'light'}>
              <Text
                fontSize={'lg2'}
                fontWeight={'bold'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: '2xl',
                  },
                }}
              >
                {t('feature.service.5.label')}
              </Text>
              <Text
                fontSize={'md'}
                mt={'0.625em'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: 'lg',
                  },
                }}
              >
                {t('feature.service.1.desc')}
              </Text>
            </Flex>
          </Grid>
        </Grid>
      </Flex>
    </Box>
  )
}

export default FeatureSection
