import React from 'react'

import { Box, Divider, Flex, Grid, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { useTranslation } from 'react-i18next'

import { localeConfigs } from '@/constants/locales'

const EquipmentSection = () => {
  const { t } = useTranslation('equipment')
  const { language } = useI18next()

  return (
    <Box
      id={'equipment-section'}
      as={'section'}
      pt={'7.5em'}
      pb={'18.281em'}
      overflow={'visible'}
      sx={{
        '&::after': {
          zIndex: -1,
          content: '""',
          pos: 'absolute',
          bottom: '0',
          left: '0',
          width: 'full',
          height: '11.458em',
          bgImage: 'linear-gradient(to top, #060606 0%, transparent 100%)',
        },
      }}
    >
      {/* Dust Middle */}
      <Box
        as={'section'}
        position={'absolute'}
        zIndex={-1}
        width={'full'}
        height={'7.79em'}
        bg="white"
        opacity={'0.125'}
        filter={'blur(9.82em)'}
        top={'0'}
        mt={'-3.35em'}
      />
      {/* Minier Left */}
      <Box
        position={'absolute'}
        zIndex={-3}
        w={'24.57em'}
        h={'auto'}
        left={'0'}
        bottom={'2.9em'}
      >
        <StaticImage
          src="../../images/pages/miner-left.png"
          alt="Miner Tank"
          layout={'fullWidth'}
          quality={80}
          objectFit={'cover'}
        />
      </Box>
      {/* Minier Right */}
      <Box
        position={'absolute'}
        zIndex={-3}
        // w={'35.26em'}
        // h={'38.02em'}
        w={'28.5em'}
        h={'auto'}
        right={'0'}
        bottom={'0'}
      >
        <StaticImage
          src="../../images/pages/miner-right.png"
          alt="Miner Tank"
          layout={'fullWidth'}
          quality={80}
          objectFit={'cover'}
        />
      </Box>

      {/* Background */}
      <Box
        position={'absolute'}
        inset={'0'}
        zIndex={-2}
        w={'full'}
        h={'full'}
        mt={'-14.77em'}
        sx={{
          '&::before': {
            zIndex: 1,
            content: '""',
            pos: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '11.458em',
            bgImage: 'linear-gradient(to bottom, #060606 0%, transparent 100%)',
          },
        }}
      >
        <StaticImage
          src="../../images/pages/background-dust.png"
          alt="Background dust"
          layout={'fullWidth'}
          quality={100}
          objectFit={'cover'}
          objectPosition={'bottom'}
        />
      </Box>

      <Flex flexDir={'column'} justifyContent={'end'}>
        <Box w={'90%'} mx={'auto'}>
          <Text
            color={'primary'}
            textStyle={'header-title'}
            letterSpacing={'0.1em'}
            textAlign={'center'}
          >
            Gold Mining Equipment
          </Text>
        </Box>

        <Box
          w={language === localeConfigs['th'].code ? '66.875em' : '48.177em'}
          mx={'auto'}
          textAlign="center"
          mt={'1.4375em'}
          sx={{
            '@media (max-width: 768px)': {
              w: '40em',
            },
          }}
        >
          <Text color={'light'} textStyle={'description-text'}>
            {t('equipment.description')}
          </Text>
        </Box>

        <Box mx={'auto'} position={'relative'} mt={'5.9375em'}>
          <Grid
            templateColumns="repeat(3, 1fr)"
            columnGap={'16.04em'}
            sx={{
              '@media (max-width: 768px)': {
                columnGap: '6.04em',
              },
              '&::after': {
                zIndex: -1,
                content: '""',
                pos: 'absolute',
                top: '2.08em',
                left: '50%',
                transform: 'translate(-50%,0)',
                width: '85%',
                height: '0.2em',
                bgImage: 'linear-gradient(90deg, #CC9F31 0%, #D9B561 100%)',
                opacity: 0.5,
              },
            }}
            letterSpacing={'0.05em'}
          >
            <Flex direction={'column'}>
              <Box w={'4.167em'} mx={'auto'}>
                <StaticImage
                  src="../../images/pages/equipment-gme.png"
                  alt="GME NFT"
                  layout={'fullWidth'}
                  quality={80}
                />
              </Box>
              <Divider w={'1.041em'} mx={'auto'} mt={'1.3em'} />
              <Text
                mt={'0.364em'}
                fontWeight={'bold'}
                fontSize={'2xl'}
                textAlign={'center'}
                color={'light'}
              >
                GME NFT
              </Text>
            </Flex>

            <Flex direction={'column'}>
              <Box w={'4.167em'} mx={'auto'}>
                <StaticImage
                  src="../../images/pages/equipment-earn.png"
                  alt="Earn"
                  layout={'fullWidth'}
                  quality={80}
                />
              </Box>
              <Divider w={'1.041em'} mx={'auto'} mt={'1.3em'} />
              <Text
                mt={'0.364em'}
                fontWeight={'bold'}
                fontSize={'2xl'}
                textAlign={'center'}
                color={'rgba(255,255,255,0.5)'}
              >
                Earn
              </Text>
            </Flex>

            <Flex direction={'column'}>
              <Box w={'4.167em'} mx={'auto'}>
                <StaticImage
                  src="../../images/pages/equipment-stake.png"
                  alt="Earn"
                  layout={'fullWidth'}
                  quality={80}
                />
              </Box>
              <Divider w={'1.041em'} mx={'auto'} mt={'1.3em'} />
              <Text
                mt={'0.364em'}
                fontWeight={'bold'}
                fontSize={'2xl'}
                textAlign={'center'}
                color={'rgba(255,255,255,0.5)'}
              >
                Stake / Trade
              </Text>
            </Flex>
          </Grid>
        </Box>
      </Flex>
    </Box>
  )
}

export default EquipmentSection
