import React from 'react'

import { Box, Divider, Grid, GridItem, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

const GoldJewSection = () => {
  return (
    <Box
      id={'goldjew-section'}
      as={'section'}
      py={'9.167em'}
      mt={'-12.25em'}
      sx={{
        '@media (max-width: 768px)': {
          pt: '0',
          mt: '0',
        },
      }}
    >
      {/* Background */}
      <Box position={'absolute'} inset={'0'} zIndex={-2} w={'full'} h={'full'}>
        <StaticImage
          src="../../images/pages/background-dust.png"
          alt="Background dust"
          layout={'fullWidth'}
          quality={100}
          objectFit={'cover'}
          objectPosition={'bottom'}
        />
      </Box>

      {/* Dust Top */}
      <Box
        position={'absolute'}
        zIndex={-1}
        width={'full'}
        height={'10em'}
        bg="#060606"
        filter={'blur(4.010em)'}
        top={'0'}
      />

      <Box display={'flex'}>
        <Grid
          templateColumns="32em 36em"
          mx={'auto'}
          mt={'4.92em'}
          transform={'translate(3em,0)'}
          sx={{
            '@media (max-width: 768px)': {
              gridTemplateColumns: '1fr',
              transform: 'translate(0,0)',
            },
          }}
        >
          <GridItem mt={'13.25em'}>
            <Text
              fontSize={'7xl'}
              fontWeight={'bold'}
              color={'primary'}
              letterSpacing={'0.1em'}
              sx={{
                '@media (max-width: 768px)': {
                  fontSize: '5xl',
                  display: 'inline',
                },
              }}
            >
              Gold NFT
            </Text>
            {/* <Text
              fontSize={'7xl'}
              fontWeight={'bold'}
              color={'primary'}
              letterSpacing={'0.1em'}
              sx={{
                '@media (max-width: 768px)': {
                  fontSize: '5xl',
                  display: 'inline',
                  ml: '0.3em',
                },
              }}
            >
              Gold Jew
            </Text>
            <Text
              fontSize={'7xl'}
              fontWeight={'bold'}
              color={'primary'}
              letterSpacing={'0.1em'}
              sx={{
                '@media (max-width: 768px)': {
                  fontSize: '5xl',
                  display: 'inline',
                },
              }}
            >
              NFT
            </Text> */}
            <Divider
              mt={'2.125em'}
              w={'1.042em'}
              sx={{
                '@media (max-width: 768px)': {
                  mx: 'auto',
                },
              }}
            />
            <Text mt={'1.042em'} textStyle={'description-text'}>
              Buy and sell Gold NFT and Ore NFT
            </Text>
          </GridItem>

          <GridItem>
            {/* Gold assets */}
            <StaticImage
              src="../../images/pages/goldjew-assets.png"
              alt="Golden Bar"
              layout={'fullWidth'}
              quality={80}
              objectFit={'contain'}
            />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}

export default GoldJewSection
