import * as React from 'react'

import loadable from '@loadable/component'
import { graphql, HeadFC, Script } from 'gatsby'

import CommunitySection from '@/components/Pages/CommunitySection'
import EcosystemSection from '@/components/Pages/EcosystemSection'
import EquipmentSection from '@/components/Pages/EquipmentSection'
import FeatureSection from '@/components/Pages/FeatureSection'
import GoldJewSection from '@/components/Pages/GoldJewSection'
import GwelSection from '@/components/Pages/GwelSection'
import HeroSection from '@/components/Pages/HeroSection'
import MarketplaceSection from '@/components/Pages/MarketplaceSection'
import PartnerSection from '@/components/Pages/PartnerSection'
import ServiceSection from '@/components/Pages/ServiceSection'
import Seo from '@/components/Seo'

const Layout = loadable(() => import('@/layouts'))

const IndexPage = () => {
  return (
    <Layout>
      <HeroSection />
      <EquipmentSection />
      <GwelSection />
      <MarketplaceSection />
      <GoldJewSection />
      <ServiceSection />
      <EcosystemSection />
      <FeatureSection />
      <PartnerSection />
      <CommunitySection />
      {/* <div id={'formform'} /> */}
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => (
  <React.Fragment>
    <Seo />
    <Script src={'//js.hsforms.net/forms/v2.js'} />
  </React.Fragment>
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
