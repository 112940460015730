import React from 'react'

import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

const PartnerSection = () => {
  return (
    <Box id={'partner-section'} as={'section'} pt={'10.417em'} pb={'19.063em'}>
      {/* Background */}
      <Box position={'absolute'} inset={'0'} zIndex={-2} w={'full'} h={'full'}>
        <StaticImage
          src="../../images/pages/background-dust.png"
          alt="Background dust"
          layout={'fullWidth'}
          quality={100}
          objectFit={'cover'}
        />
      </Box>

      <Flex flexDir={'column'} justifyContent={'center'}>
        <Text
          color={'white'}
          textAlign={'center'}
          fontSize={'6xl2'}
          fontWeight={'bold'}
          letterSpacing={'0.1em'}
        >
          Partner
        </Text>

        <Grid
          templateColumns="repeat(3, 14.167em)"
          mx={'auto'}
          gap={'2.5em'}
          mt={'5.677em'}
          sx={{
            '@media (max-width: 768px)': {
              gridTemplateColumns: 'repeat(2, 14.167em)',
            },
          }}
        >
          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-wellios.png"
                alt="Wellios"
                quality={100}
                layout="fullWidth"
              />
            </Box>
          </GridItem>

          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-wellexp.png"
                alt="Wellexp"
                quality={80}
                layout="fullWidth"
              />
            </Box>
          </GridItem>

          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-tofunft.png"
                alt="Tofu Nft"
                quality={100}
                layout="fullWidth"
              />
            </Box>
          </GridItem>

          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-bsc.png"
                alt="Binance Smart Chain"
                quality={100}
                layout="fullWidth"
              />
            </Box>
          </GridItem>

          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-metamask.png"
                alt="Metamask"
                quality={100}
                layout="fullWidth"
              />
            </Box>
          </GridItem>

          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-pancake.png"
                alt="Pancake Swap"
                quality={100}
                layout="fullWidth"
              />
            </Box>
          </GridItem>

          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-dekguru.png"
                alt="Dekguru"
                quality={100}
                layout="fullWidth"
              />
            </Box>
          </GridItem>

          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-arken.png"
                alt="Arken"
                quality={100}
                layout="fullWidth"
              />
            </Box>
          </GridItem>

          <GridItem display={'flex'}>
            <Box m={'auto'} w={'19.167em'}>
              <StaticImage
                src="../../images/pages/partner-poocoin.png"
                alt="Poocoin"
                quality={100}
                layout="fullWidth"
              />
            </Box>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  )
}

export default PartnerSection
