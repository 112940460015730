import React from 'react'

import { Box, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

const GwelSection = () => {
  const { t } = useTranslation(['gwel'])

  return (
    <Box id={'gwel-section'} as={'section'} pb={'5.68em'}>
      {/* Background */}
      <Box
        position={'absolute'}
        inset={'0'}
        zIndex={-2}
        w={'full'}
        h={'full'}
        sx={{
          '&::before': {
            zIndex: 1,
            content: '""',
            pos: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '41.458em',
            bgImage: 'linear-gradient(to bottom, #060606 0%, transparent 100%)',
          },
          '&::after': {
            zIndex: 1,
            content: '""',
            pos: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            height: '11.458em',
            bgImage: 'linear-gradient(to top, #060606 0%, transparent 100%)',
          },
        }}
      >
        <StaticImage
          src="../../images/pages/background-dust.png"
          alt="Background dust"
          layout={'fullWidth'}
          quality={100}
          objectFit={'cover'}
          objectPosition={'bottom'}
        />
      </Box>

      <Flex flexDir={'column'} justifyContent={'center'}>
        <Box w={'90%'} mx={'auto'}>
          <Text
            color={'primary'}
            textStyle={'header-title'}
            textAlign={'center'}
            letterSpacing={'0.1em'}
          >
            GWEL
            <Text
              as={'span'}
              color={'white'}
              display="inline-block"
              ml={'0.417em'}
            >
              Token
            </Text>
          </Text>
        </Box>

        <Divider w={'1.67em'} mx={'auto'} mt={'1.34em'} />
        <Box
          textAlign="center"
          mt={'1.3125em'}
          sx={{
            '@media (max-width: 768px)': {
              w: '38em',
              mx: 'auto',
            },
          }}
        >
          <Text color={'light'} textStyle={'description-text'}>
            {t('description')}
          </Text>
        </Box>

        <Grid
          templateColumns="repeat(3, 18.45em)"
          columnGap={'2.343em'}
          mt={'4.11em'}
          mx={'auto'}
          letterSpacing={'0.05em'}
          sx={{
            '@media (max-width: 768px)': {
              gridTemplateColumns: '1fr',
              columnGap: '0',
              rowGap: '4.13em',
            },
          }}
        >
          <GridItem
            display={'flex'}
            flexDirection={'column'}
            sx={{
              '@media (max-width: 768px)': {
                w: '18.45em',
                mx: 'auto',
              },
            }}
          >
            <Box w={'10.41em'} mx={'auto'}>
              <StaticImage
                src="../../images/pages/gwel-earn-gwel.png"
                alt="earn more Gwel"
                layout={'fullWidth'}
                quality={80}
              />
            </Box>
            <Divider w={'1.04em'} mt={'1.6em'} mx={'auto'} mb={'0.36em'} />
            <Text
              textAlign={'center'}
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'light'}
              sx={{
                fontSize: '2xl',
                marginTop: '0.6em',
              }}
            >
              {t('earn1')}
            </Text>
          </GridItem>

          <GridItem
            display={'flex'}
            flexDirection={'column'}
            sx={{
              '@media (max-width: 768px)': {
                w: '18.45em',
                mx: 'auto',
              },
            }}
          >
            <Box w={'10.41em'} mx={'auto'}>
              <StaticImage
                src="../../images/pages/gwel-earn-gold.png"
                alt="Gold Jewelry NFT"
                layout={'fullWidth'}
                quality={80}
              />
            </Box>
            <Divider w={'1.04em'} mt={'1.6em'} mx={'auto'} mb={'0.36em'} />
            <Text
              textAlign={'center'}
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'light'}
              sx={{
                fontSize: '2xl',
                marginTop: '0.6em',
              }}
            >
              {t('earn2')}
            </Text>
          </GridItem>

          <GridItem
            display={'flex'}
            flexDirection={'column'}
            sx={{
              '@media (max-width: 768px)': {
                w: '18.45em',
                mx: 'auto',
              },
            }}
          >
            <Box w={'10.41em'} mx={'auto'}>
              <StaticImage
                src="../../images/pages/gwel-earn-ore.png"
                alt="The valuable Ore NFT as the reward"
                layout={'fullWidth'}
                quality={80}
              />
            </Box>
            <Divider w={'1.04em'} mt={'1.6em'} mx={'auto'} mb={'0.36em'} />
            <Text
              textAlign={'center'}
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'light'}
              sx={{
                fontSize: '2xl',
                marginTop: '0.6em',
              }}
            >
              {t('earn3')}
            </Text>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  )
}

export default GwelSection
