import React, { useEffect } from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import { useTranslation } from 'react-i18next'

import Button from '../Base/Button'

const HeroSection = () => {
  const { t } = useTranslation(['hero'])

  const animate = () => {
    const timeline = gsap.timeline()

    const body = document.getElementsByTagName('body')

    gsap.set(body, {
      overflow: 'hidden',
    })

    gsap.set(
      [
        '#hero-gold-left',
        '#hero-gold-right',
        '#hero-gold-mining',
        '#hero-metaverse',
        '#hero-header-desc',
        '#hero-buypack',
      ],
      {
        opacity: 0,
      }
    )

    gsap.set('#hero-gold-left', {
      x: -50,
    })
    gsap.set('#hero-gold-right', {
      x: 50,
    })
    gsap.set('#hero-header-desc', {
      y: -40,
    })

    timeline
      .to('#hero-gold-mining', {
        opacity: 1,
        duration: 0.4,
        ease: 'power2.in',
      })
      .to('#hero-metaverse', {
        opacity: 1,
        duration: 0.6,
        ease: 'power2.in',
      })
      .to('#hero-header-desc', {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: 'power2.inOut',
      })
      .to(['#hero-gold-left', '#hero-gold-right'], {
        x: 0,
        opacity: 1,
        duration: 0.4,
      })
      .to('#hero-buypack', {
        y: 0,
        opacity: 1,
        duration: 0.4,
      })
      .to(body, {
        overflowX: 'hidden',
        overflowY: 'visible',
      })
  }

  // Animate Transition
  // useEffect(() => {
  //   animate()
  // }, [])

  return (
    <Box id={'hero-section'} as="section" display={'relative'} zIndex={1}>
      {/* Dust Top */}
      <Box
        position={'absolute'}
        zIndex={-1}
        width={'79.21em'}
        height={'8.72em'}
        bg="#FFF"
        opacity={'0.2'}
        filter={'blur(9.82em)'}
        top={'0'}
      />

      {/* Left // Gold Bar */}
      <Box
        id={'hero-gold-left'}
        w={'18.81em'}
        zIndex={-1}
        className={'absolute-center'}
        marginLeft={'-25.1875em'}
        marginTop={'5.33em'}
        sx={{
          '@media (max-width: 768px)': {
            marginLeft: '-18.1875em',
            marginTop: '8.33em',
            w: '11.81em',
          },
        }}
      >
        <StaticImage
          src="../../images/pages/mining-gold-left.png"
          alt="Gold bar"
          layout={'fullWidth'}
          quality={80}
        />
      </Box>

      {/* Righ // Gold Bar */}
      <Box
        id={'hero-gold-right'}
        w={'21.927em'}
        zIndex={-1}
        className={'absolute-center'}
        marginLeft={'21.8125em'}
        marginTop={'10.329em'}
        sx={{
          '@media (max-width: 768px)': {
            marginLeft: '16.8125em',
            w: '14.81em',
          },
        }}
      >
        <StaticImage
          src="../../images/pages/mining-gold-right.png"
          alt="Gold bar"
          layout={'fullWidth'}
          quality={80}
        />
      </Box>

      <Flex
        flexDir={'column'}
        h={'48em'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 768px)': {
            h: '74em',
          },
        }}
      >
        <Flex flexDir={'column'} w={'full'} alignItems={'center'}>
          <Text
            id={'hero-gold-mining'}
            fontSize={{ base: '7xl', lg: '6xl2' }}
            textTransform={'uppercase'}
            fontWeight={'bold'}
            letterSpacing={'0.05em'}
          >
            Gold Mining
          </Text>
          <Text
            id={'hero-metaverse'}
            fontSize={{ base: '9xl', lg: '8xl' }}
            textTransform={'uppercase'}
            fontWeight={'bold'}
            color={'primary'}
            letterSpacing={'0.05em'}
          >
            Metaverse
          </Text>
          <Box id={'hero-header-desc'} w={'28em'} textAlign={'center'}>
            <Text as={'h1'} color={'light'} textStyle={'description-text'}>
              {t('headerText')}
            </Text>
          </Box>

          <Button.LinkButton
            id={'hero-buypack'}
            href="https://app.xwel.io/metaverse/buy-pack"
            target="_blank"
            rel="noreferrer"
            variant={'primary'}
            w={'11.25em'}
            h={'2.65625em'}
            py={'0.83em'}
            mt={'2.77em'}
            sx={{
              '@media (max-width: 768px)': {
                w: '14.25em',
                h: '4.65625em',
              },
            }}
          >
            <Text
              as="span"
              fontSize={'md'}
              fontWeight={'bold'}
              textTransform={'uppercase'}
              letterSpacing={'0.1em'}
              sx={{
                '@media (max-width: 768px)': {
                  fontSize: '2xl',
                },
              }}
            >
              Buy Pack
            </Text>
          </Button.LinkButton>
        </Flex>
      </Flex>
    </Box>
  )
}

export default HeroSection
