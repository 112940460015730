export const localeConfigs: ILocale = {
  en: {
    code: 'en',
    label: 'english',
    flag: 'us',
  },
  th: {
    code: 'th',
    label: 'thai',
    flag: 'th',
  },
}

export default {
  localeConfigs,
}
